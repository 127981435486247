import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3a5da730"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "datacustomer-wrap"
};
const _hoisted_2 = {
  class: "datacustomer-content-box"
};
const _hoisted_3 = {
  id: "companyDefaultLogo",
  width: "120",
  height: "120"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_t_layout = _resolveComponent("t-layout");
  return _openBlock(), _createBlock(_component_t_layout, {
    "is-white-bg": true,
    "is-header-padding": true
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_router_view)]), _withDirectives(_createElementVNode("canvas", _hoisted_3, null, 512), [[_vShow, false]])])]),
    _: 1
  });
}